// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blogger-index-tsx": () => import("./../../../src/pages/blogger/index.tsx" /* webpackChunkName: "component---src-pages-blogger-index-tsx" */),
  "component---src-pages-designer-league-index-tsx": () => import("./../../../src/pages/designerLeague/index.tsx" /* webpackChunkName: "component---src-pages-designer-league-index-tsx" */),
  "component---src-pages-designer-press-bill-index-tsx": () => import("./../../../src/pages/designerPress/bill/index.tsx" /* webpackChunkName: "component---src-pages-designer-press-bill-index-tsx" */),
  "component---src-pages-designer-press-contract-index-tsx": () => import("./../../../src/pages/designerPress/contract/index.tsx" /* webpackChunkName: "component---src-pages-designer-press-contract-index-tsx" */),
  "component---src-pages-designer-press-designer-index-index-tsx": () => import("./../../../src/pages/designerPress/designerIndex/index.tsx" /* webpackChunkName: "component---src-pages-designer-press-designer-index-index-tsx" */),
  "component---src-pages-designer-press-full-info-index-tsx": () => import("./../../../src/pages/designerPress/fullInfo/index.tsx" /* webpackChunkName: "component---src-pages-designer-press-full-info-index-tsx" */),
  "component---src-pages-designer-press-joint-info-index-tsx": () => import("./../../../src/pages/designerPress/jointInfo/index.tsx" /* webpackChunkName: "component---src-pages-designer-press-joint-info-index-tsx" */),
  "component---src-pages-designer-press-login-index-tsx": () => import("./../../../src/pages/designerPress/login/index.tsx" /* webpackChunkName: "component---src-pages-designer-press-login-index-tsx" */),
  "component---src-pages-designer-press-register-index-js": () => import("./../../../src/pages/designerPress/register/index.js" /* webpackChunkName: "component---src-pages-designer-press-register-index-js" */),
  "component---src-pages-designer-press-select-clothes-index-tsx": () => import("./../../../src/pages/designerPress/selectClothes/index.tsx" /* webpackChunkName: "component---src-pages-designer-press-select-clothes-index-tsx" */),
  "component---src-pages-designer-press-verify-index-tsx": () => import("./../../../src/pages/designerPress/verify/index.tsx" /* webpackChunkName: "component---src-pages-designer-press-verify-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

